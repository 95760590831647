.faq-accordion-item h2 svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-left: 8px;
  color: var(--theme-ui-colors-pl-dark);
}

.faq-accordion-item section p {
  margin-bottom: 20px;
  line-height: 1.2em;
}