.react-multi-carousel-list.info-carousel-container {
    width: calc(100vw - 40px);
}

.info-carousel-dot-list {
  gap: 21px;
}

.info-carousel-dot-list .inactive {
  color: #BBB;
}

.info-carousel-container .react-multiple-carousel__arrow {
  z-index: 10;
}

.info-carousel-container .react-multiple-carousel__arrow,
.info-carousel-container .react-multiple-carousel__arrow:hover {
  min-width: 16px;
  min-height: 16px;
  padding: 2px;
  background: transparent;
}

.info-carousel-container  .react-multiple-carousel__arrow::before {
  font-size: 14px;
  color: rgba(0,0,0,0.65)
}

.info-carousel-container  .react-multiple-carousel__arrow:hover::before {
  color: rgba(0,0,0,0.75)
}

.info-carousel-container .react-multiple-carousel__arrow--left {
  left: -5px;
}

.info-carousel-container .react-multiple-carousel__arrow--right {
  right: -5px;
}

.testimonials-carousel-dot-list {
  gap: 21px;
}

.testimonials-carousel-dot-list .inactive {
  color: #BBB;
}

.testimonials-carousel-container .react-multiple-carousel__arrow {
  z-index: 10;
}
